footer {
	width: 100%;
}
footer .footer-div {
	display: block;
    min-height:270px;
	width: 100%;
	color: #ffffff;
	background: #000000 url('/_resources/themes/newsline/dist/images/branding/CCCFooterPattern-tile.jpg') repeat-x bottom left;
	background-size: 100px 69px;
}
footer a:focus, footer a:focus, footer button:focus {
	outline: none;
}

/*********************************
* Accordian menu styling - footer *
**********************************/
footer .section-header-wrapper .clickable .arrow {
	border: none;
	cursor: pointer;
	background: 0 0;
	font-size: 16px;
	padding: 8px;
	float: right;
	font-size: 150%;
}
footer .footer-accordian {
	cursor: pointer;
}
footer .footer-accordian em {
	padding: 0 15px;
	font-size: 125%;
}
footer .footer-accordian ul li i {
	float: right;
	padding: 0 15px;
}
footer .footer-accordian button.arrow {
	outline: none;
}
footer .footer-accordian-button-text {
	height: 60px;
	display: flex;
    justify-content: space-between;
	width: 100%;
}
footer .footer-accordian ul {
	list-style: none;
	padding: 4% 0 0 0;
	margin-bottom: 0;
}
footer .footer-top-line h3 {
	margin: 20px 0 10px 0;
	font-size: 100%;
	margin-right: 30px;
}
footer button.arrow {
	margin: 17px 0;
}

/* Footer accordian - chevron button */
@media (max-width: 767px) {
	footer button {
		float: right;
		background: none;
		border: none;
	}
}
.subsite-footer .social-table-cell {
    display: flex;
    align-items: center;
}
/* Footer link buttons */
footer a.button-link button {
	background-color: #000000;
	color: #ffffff;
	border: 2px solid #ffffff;
	padding: 2px 7px;
}
footer a:hover.button-link button, footer a:focus.button-link button {
	background-color: #ffffff;
	color: #000000;
	border: 2px solid #ffffff;
}
footer a:active.button-link button {
	background-color: #414042;
	color: #ffffff;
	border: 2px solid #414042;
}
footer .button-spacing {
	margin-top: 10px;
	margin-bottom: 10px;
}
footer a:hover.button-link button span {
	color:#000000 !important;
}
footer a.button-wrapper button span {
	color:#ffffff !important;
}
footer .footer-div h3 {
	font-weight: 400;
}
footer .footer-logo-and-copyright {
	float: right;
	padding-bottom: 10px;
}
footer .footer-logo a img {
	width: 300px;
}
footer .footer-list li {
	display: inline-block;
	padding: 0;
	margin: 0 0 5px 0;
}
footer .footer-list a {
	color: #ffffff;
	margin: 3px 30px 3px 0;
	padding: 0;
	line-height: 100%;
	text-decoration: none;
}
footer .footer-list a p {
	padding-right: 20px;
}
footer .footer-list a:hover, footer .footer-list a:focus, footer .footer-list a:active {
	text-decoration: none;
}
footer .footer-list a:hover span, footer .footer-list a:focus span, footer .footer-list a:active span {
	border-bottom: 2px solid #ffffff;
}
footer .popular {
	margin-left: 8%;
}
footer .govt-logo {
	height: 69px;
}
footer .social-table {
	display: table;
	color: #ffffff;
	margin: 0;
	margin-left: auto;
	margin-right: auto;
	padding: 0;
	padding-bottom: 2.5%;
	text-align: left;
	float: left;
	height: 50px;
}
footer .footer-div .social-table-row {
	display: flex;
}
footer .social-table-cell {
	vertical-align: middle;
	height: 50px;
}
footer .social-table-cell a {
	margin-right: 10px;
	color: #ffffff;
}
footer .contact-table a, footer .contact-table a:hover, footer .contact-table a:focus, footer .contact-table a:active {
	text-decoration: none;
}
footer .contact-table a:hover span:not(.fa-stack), footer .contact-table a:focus span:not(.fa-stack), footer .contact-table a:active span:not(.fa-stack) {

	border-bottom: 2px solid #ffffff;
}
@media (max-width: 375px) {
	footer .social-table-cell a {
		margin-right: 0px;
	}
}
/*****************************
* Footer Google Translation styling *
*****************************/
footer .translate li {
	display: inline-block !important;
}
footer .translate-lang {
	display: inline;
}
footer .translate .translate-lang p {
	margin: 0;
	display: inline;
    padding: 0;
    font-size: 14px;
    font-weight: 600;
}
footer .translate a {
    padding: 0 5px 0 0;
    border-bottom: 2px solid transparent;
}
footer .translate a, footer .translate a:visited {
	margin: 3px 5px 3px 0;
	outline: none;
}
footer .translate a:hover p, footer .translate a:focus p, footer .translate a:active p,
footer .translate a:hover h3, footer .translate a:focus h3, footer .translate a:active h3 {
	border-bottom: 2px solid #ffffff;
}
/* End translation styling */
@media only screen and (min-width: 768px) {
	footer .footer-logo-and-copyright {
		margin: 20px 15px 0 0;
	}
}
@media only screen and (min-width: 992px) {
	footer .footer-logo-and-copyright {
		margin: 0 8px 0 0;
	}
}
@media (min-width: 768px) {
	footer .tandc {
    	padding: 23px 0 0 0;
	}
}
@media (min-width: 1080px) {
	#subsite-footer > div > div > div > div > div:nth-child(1) > a {
		margin-right: 0px;
	}
}
footer .footer-top-line {
	border-top: 1px solid #ffffff;
	margin-top: -2px;
	padding: 0;
}
footer .footer-top-line h3 {
	margin: 20px 0 10px 0;
	font-size: 100%;
	margin-right: 30px;
}
footer .social-table a {
	color: #ffffff;
	display: inline-block;
}
footer .footer-socmed {
	margin-bottom: 20px;
}
footer .footer-list {
    padding: 0 0 15px 0;
    margin: 0;
}
footer .footer-top {
    margin: 0;
    padding: 20px 0;
}
footer .footer-copyright {
    margin: 0;
    padding: 20px 0;
}
footer .footer-copyright ul {
    padding-bottom: 0;
}
footer .footer-footer {
    font-size: 10px;
}
.popular-links-list ul li a {
    display: block;
    width: 100%;
    text-decoration: none;
    padding: 5px;
    color: #414042;
}
@media (max-width: 767px) {

    footer .footer-div .social-table-row {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
	}

    footer .footer-socmed {
		display: flex;
    	justify-content: space-between;
	}

	footer .footer-socmed a {
		margin: 0 10px 0 0;
	}

	footer .footer-div .small {
		font-size: 100%;
	}

	footer .footer-div .footer-copyright {
		border-top: 1px solid #fff;
	}

	footer .footer-div .translate a h3 {
		display:inline-block;
		margin: 20px 5px 3px 0;
		padding: 0 5px 0 0;
		line-height: 16px;
		font-size: 14px;
	}

	footer .popular-links-list {
		margin: 0px -15px;
    	padding: 0px 15px;
		background: #ffffff;
	}
	footer .popular-links-list ul {
		padding: 0;
		margin: 0;
	}

	footer .popular-links ul {
		padding: 0;
		margin: 0;
		list-style-type: none;
	}
	footer .popular-links li {
		padding: 0;
		white-space: nowrap;
	}

	footer .popular-links-list li a {
		color: rgb(10, 113, 113);
		border-bottom: 1px solid rgb(230, 231, 232);
		padding-top: 10px;
		font-weight: 600;
	}
    footer .govt-logo {
		text-align: left;
	}
	footer .govt-logo a img {
		margin-top: 20px;
		width: 200px;
	}
    footer .contact-table img {
		margin: 10px 10px 10px 0;
	}
	footer .popular-links-list ul > li:first-child {
		display: none;
	}
}
@media (min-width: 768px) {
    footer a {
		text-decoration: none;
		color: #ffffff;
	}
	footer a h3 {
		margin: 20px 0 0 20px 0;
		display: inline-block;
		border-bottom: 2px solid #000000;
	}
	footer .popular-links-list ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}
	footer a:hover h3, footer a:focus h3, footer a:active h3 {
		border-bottom: 2px solid #ffffff;
	}
	footer .footer-list {
		margin-bottom: 0;
	}
    footer .footer-socmed a {
		margin: 0 20px 0 0;
	}
	footer .footer-socmed a:last-child {
		margin: 0;
	}
    footer .contact-table img {
		margin: 0 10px 0 0;
	}
	footer .govt-logo {
		text-align: right;
		line-height: 68px;
	}
	footer .govt-logo a {
		float: left;
	}
	footer .govt-logo a img {
		width: 150px;
	}
	footer .collapse:not(.show) {
		display: block;
	}
	footer .popular-links-list {
		display: block;
	}
	footer .popular-links-list li {
		display: inline-block;
		padding: 0;
		margin: 0 0 5px 0;
	}
	footer .popular-links-list a  {
		color: #ffffff;
		margin: 3px 30px 3px 0;
		padding: 0 !important;
		line-height: 100%;
		text-decoration: none;
	}
	footer .popular-links-list span {
		color:#ffffff;
	}
	footer .popular-links-list a p {
		padding-right: 20px;
	}
	footer .popular-links-list a:hover, footer .popular-links-list a:focus, footer .popular-links-list a:active {
		text-decoration: none;
	}
	footer .popular-links-list a:hover span, footer .popular-links-list a:focus span, footer .popular-links-list a:active span {
		border-bottom: 2px solid #ffffff;
	}
	footer .footer-accordian-button-text {
		display: none;
	}
	footer .popular-links-list {
		display: block;
		background: #000000;
	}
	footer .popular-links-list {
		margin: 0;
    	padding: 0 0 20px 0;
    	list-style-type: none;
	}
}
@media (min-width: 992px) {
	footer .social-table-cell {
		padding: 0 0px 10px 0;
	}
	footer .footer-socmed a {
		margin: 0 14px 0 0;
	}
    footer .footer-copyright {
		padding: 23px 0 0 0;
	}
}
@media (min-width: 1200px) {
    footer .social-table-cell {
		padding: 0 20px 10px 0;
	}
	footer .footer-socmed a {
		margin: 0 20px 0 0;
	}
	footer .footer-copyright {
		margin: 23px 0 0 0;
		padding: 0;
	}
	footer .govt-logo a img {
		width: 225px;
	}
}
footer .footer-div .container {
	position: relative;
}
footer #shielded-logo {
	position: absolute;
	bottom: 20px;
	right: 15px;
}

/* Shielded logo */
@media (max-width: 767px) {
	#shielded-logo {
		position: relative;
		bottom: 45px;
		right: 15px;
	}
}
@media (min-width: 768px) {
	#shielded-logo {
		position: relative;
		bottom: 45px;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	#shielded-logo {
		right: calc(0.5 * (100% - 738px));
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	#shielded-logo {
		right: calc(0.5 * (100% - 962px));
	}
}
@media (min-width: 1200px) {
	#shielded-logo {
		right: calc(0.5 * (100% - 1169px));
	}
}